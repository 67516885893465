.search-btn{
    cursor: pointer;
    height: 30px;
    width: 100px;
    position:sticky;
    margin-left: -7.6rem;
    margin-top:  0rem;
    border-radius: 5rem;
    background: #335675;
    border: none;
    font-size: 20px;
    color: #f2f3f8;
    text-align: center;
    justify-content: center;
}

input .search-controller-topbar{
    border-radius: 5rem;
  
    position: relative;
    
}
.nav-item-nav-profile-d-none-d-xl-flex{
    transform: rotate(90deg);
}

.drop{
    
    align-self: auto;
    width: auto;
   
}

@media only screen and (min-width: 601px) {

    .search-item{
        display: none;
    }
    

  }

  @media only screen and (max-width: 600px) {

    .search-item{
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        z-index: 50;
        background: none;
        text-decoration: none;
        padding-top: 0.4rem;
        margin-right: 0.5rem;
    }

    .modal-search-mobile{
        border: none;
        top:2rem;
       
    }
    

  }

  .dropdown-link{   
        text-decoration: none;
        color: inherit;
        display: flex;
        flex-direction: row; 
        margin-left: -1.5rem;  
  }

  .link-left{
    
    text-align: left;
  }

  .link-right{
    text-align: right;
  }

  .dropdown-link:hover{
    color:black
  }

 