.login_container {
	width: 100%;
	min-height: 100vh;
	background: linear-gradient(180deg, rgba(80,130,175,1) 0%, rgb(32, 65, 95) 75%);
	display: flex;
	align-items: center;
	justify-content: center;
}

.login_form_container {
	padding-top: 20vh;
	
	display: flex;
	border-radius: 10px;
	
}

.left {
	flex: 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: none;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	margin-top: -15rem;
}

.form_container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.form_container h1 {
	font-size: 40px;
	margin-top: 0;
}

.input {
	outline: none;
	border: none;
	width: 350px;
	padding: 15px;
	border-radius: 10px;
	background:"white";
	margin: 5px 0;
	font-size: 14px;
}

.error_msg {
	width: 350px;
	padding: 15px;
	margin: 5px 0;
	font-size: 14px;
	background-color: #f34646;
	color: white;
	border-radius: 5px;
	text-align: center;
}

.right {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #3bb19b;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.right h1 {
	margin-top: 0;
	color: white;
	font-size: 40px;
	align-self: center;
}

.white_btn,
.green_btn {
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: white;
	border-radius: 20px;
	width: 180px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
}

.green_btn {
	background-color: #41bd97;
	color: rgb(82, 82, 82);
	margin-top: 2rem;
	
}

.green_btn:hover{
	background: #f34646;
	color: white;
}


.footer_wrapper{
	padding-top: 7rem;
	color: white;
	display: flex;
	justify-content: space-between;
	
}
