.login-container {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(180deg, rgba(80, 130, 175, 1) 0%, rgb(32, 65, 95) 75%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

}

.login-logo-wrapper {
    text-align: center;
    justify-content: center;
}

.login-form-wrapper {
    justify-content: center;
}

.login-footer-wrapper {
    text-align: center;
    margin-top: 5rem;
    justify-content: center;
}